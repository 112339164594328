import translations from './translationApi';

const defaultRegionForLocale = {
  de: 'de',
  nl: 'nl',
  en: 'gb',
  es: 'es',
  fr: 'fr',
  it: 'it'
};

export default class UserPreferences {
  static getPreSelectedLocaleOrDefault() {
    const availableTranslations = translations.getAvailableTranslationsList();

    const preSelectedLanguage = global.localStorage.getItem('locale');
    const preSelectedRegion = global.localStorage.getItem('region');
    const language = global.navigator && global.navigator.language;
    let lang = null;
    let region = null;

    if (preSelectedLanguage) {
      lang = preSelectedLanguage;
      region = preSelectedRegion || defaultRegionForLocale[lang] || lang;
    } else if (language) {
      [lang, region] = language.split('-');
    }

    if (!lang
      || !region
      || !availableTranslations.find(availableTranslation =>
        availableTranslation.locale.toUpperCase() === lang.toUpperCase()
        && availableTranslation.region.toUpperCase() === region.toUpperCase())) {
      [lang, region] = window.appSettings.defaultLanguage.split('-');
    }

    if (!lang || !region) {
      const { locale, region: reg } = availableTranslations[0];
      lang = locale;
      region = reg;
    }

    lang = lang.toLowerCase();
    region = region.toLowerCase();

    return {
      lang,
      region
    };
  }
}
